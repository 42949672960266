:local(.header) {
  height: 0px;
  border-bottom: none;
}

:local(.content) {
  border-top: none;
  max-width: 375px;
}

:local(.layout) {
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    flex-direction: row;
}

:local(.icon-banned) {
    width: 108px;
    height: 108px;
    margin: 0 auto;
    margin-bottom: 57px;
    border-radius: 3px;
}

:local(.subtitle) {
  font-weight: 600;
  margin-bottom: 46px;
}

:local(.button) {
  height: 60px;
  border-radius: 3px;
  max-width: 200px;
  margin-bottom: 46px;
}

:local(.text) {
  color: #3F3E3E;
  font-size: 18px;
  font-weight: 400;
}